import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { behance, upwork, logo, NavbarMenuIcon, facebook_logo, instagram_logo, linded_in_logo, upwork_logo, behance_logo, DitinusLogo, linkedinlogo, downArrow } from "src/assets";
import './styles.css'
import { motion } from 'framer-motion';
const Header = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 991);
  const [openAccordion, setOpenAccordion] = useState(null); // Track the open accordion item

  // Handle opening an accordion item
  const handleAccordionToggle = (id: any) => {
    // If the clicked accordion is already open, close it, otherwise open it
    setOpenAccordion(openAccordion === id ? null : id);
  };
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWebMenuOpen, setIsWebMenuOpen] = useState(false);
  const [isBlogs, setIsBlogs] = useState<boolean>(false);
  const [clicked, setClicked] = useState(false);
  const [company, setCompany] = useState(false);
  const [service, setService] = useState(false);
  const [hire, setHire] = useState(false);
  const [outsource, setOutsource] = useState(false);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 991);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleWebMenu = () => {
    setIsWebMenuOpen(!isWebMenuOpen);
  };

  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const navTabs = document.querySelector(".navigation");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (navTabs) {
        if (currentScroll > 20) {
          navTabs.classList.add(toggleClass);
        } else {
          navTabs.classList.remove(toggleClass);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseOver = () => {
    setDropdownVisible(true);
  };

  const handleMouseOut = () => {
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (isWebMenuOpen) {
      // Lock scroll when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scroll when modal is closed
      document.body.style.overflow = 'auto';
    }

    // Cleanup the effect when the component unmounts or when isModalOpen changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isWebMenuOpen]);
  return (
    <div>
      <header className="d-flex align-items-center justify-content-center w-100 header flex-column navigation">
        <nav className="navbar navbar-expand-lg container">
          <Link
            className="web-nav-logo-tab text-decoration-none text-denim fs-4"
            to="/"
          >
            <img loading="lazy" src={logo} alt="img" />
          </Link>
          <div className="web-nav-links w-100 align-items-center justify-content-center d-flex menu-header">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${location.pathname === "/" ? "active" : ""
                    }`}
                  to="/"
                >
                  Company<i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 9 }}>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/about-us" ? "active" : ""
                        }`}
                      to="/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/training" ? "active" : ""
                        }`}
                      to="/training"
                    >
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/career" ? "active" : ""
                        }`}
                      to="/career"
                    >
                      Career
                    </Link>
                  </li>
                  {isBlogs && (
                    <li>
                      <Link
                        className={`nav-link ${location.pathname === "/blogs" ? "active" : ""
                          }`}
                        to="/blogs"
                      >
                        Blogs
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${location.pathname === "/services" ? "active" : ""
                    }`}
                  to="/services"
                >
                  Services <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 5 }}>
                  <li className="nav-item dropdown-link">
                    <Link
                      className={`nav-link ${location.pathname === "" ? "active" : ""
                        }`}
                      to="/ui-ux"
                    >
                      Design <i className="bi bi-chevron-down ms-1" />
                    </Link>
                    <ul className="sub-menu1" style={{ padding: 5 }}>
                      <li className="nav-item dropdown-link">
                        <Link
                          className={`nav-link ${location.pathname === "/ui-ux" ? "active" : ""
                            }`}
                          to="/ui-ux"
                        >
                          UI/UX
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`nav-link ${location.pathname === "/graphics" ? "active" : ""
                            }`}
                          to="/graphics"
                        >
                          Graphics
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/software-development"
                        ? "active"
                        : ""
                        }`}
                      to="/software-development"
                    >
                      Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/digital-marketing"
                        ? "active"
                        : ""
                        }`}
                      to="/digital-marketing"
                    >
                      Digital Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === "/case-studies" ? "active" : ""
                    }`}
                  to="/case-studies"
                >
                  Case Studies
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""
                    }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className=" web-nav-links  desktop-view d-flex flex-row ms-auto align-items-center">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <div
                  className="nav-link position-relative"
                  // to="/"
                  style={{
                    width: 115,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Hire Us On<i className="bi bi-chevron-down ms-1" />
                </div>
                <ul
                  style={{
                    width: 117,
                    padding: 8,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 19,
                  }}
                >
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.upwork.com/agencies/1293525604405526528/"
                    >
                      <img loading="lazy" src={upwork} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.behance.net/ditinus"
                    >
                      <img loading="lazy" src={behance} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className=" web-nav-links  navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link ">
                <Link
                  className="nav-link"
                  to="/business-process-services"
                  style={{
                    width: 170,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Outsource With Us <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul className="menuDropDownNew" >
                  <li>
                    <Link className="nav-link text-white" to="/business-process-services">
                      Business Process Services
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            {/* <div onClick={toggleWebMenu} className="d-flex justify-content-center p-3  nav-menu-button" style={{ cursor: 'pointer' }}  >
              <img src={NavbarMenuIcon} alt="" />
            </div> */}
          </div>
          {!isDesktop && <div onClick={toggleWebMenu} className="d-flex justify-content-center p-3 nav-menu-button" style={{ cursor: 'pointer' }} >
            <img src={NavbarMenuIcon} alt="" style={{ width: "30px" }} />
          </div>}
          {/* <button
            onClick={toggleMenu}
            className="web-nav-toggle border-0 p-0 text-white toggleMenuBar "
          >
            <i className="bi bi-list display-3 d-flex align-items-center " />
          </button> */}
        </nav>
      </header>

      <div className={`bg-shadow-cover ${isMenuOpen ? "showShadow" : ""} `} />
      <div
        className={`mobile-nav-sidebar navbar-toggler ${isMenuOpen ? "showMenu" : ""
          }`}
      >
        <div className="cover-mobile-menu" >
          <div className="mobile-logo-header d-flex">
            <Link
              className="web-nav-logo text-decoration-nonepx-4 text-denim fs-4"
              to="/home"
            >
              <img loading="lazy" src={logo} alt="img" />
            </Link>
            <button
              type="button"
              onClick={toggleMenu}
              className="text-hover-gold ms-auto toggleBtnClose bg-transparent border-0"
            >
              <i className="bi bi-x-lg text-white fs-1" />
            </button>
          </div>
          <div className="mobile-menu-body py-0 flex-column d-flex">
            <ul className="navbar-nav mb-2 mb-lg-0 mobileNavigation">
              <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center text-white w-100">
                <li

                  className="nav-item dropdown-link">
                  <Link className="nav-link d-flex justify-content-between" to="/">
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Company
                    </a> <i className=" bi bi-chevron-down ms-1" onClick={() => { setCompany(!company); setClicked(false) }} />
                  </Link>
                  <ul style={{ width: "300px !important", marginLeft: '150px' }} className={company ? "" : "d-none"}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/about-us"
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/training"
                      >
                        Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/career"
                      >
                        Career
                      </Link>
                    </li>
                    {isBlogs && (
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          to="/blogs"
                        >
                          Blogs
                        </Link>
                      </li>)}
                  </ul>
                </li>
                <li
                  className="nav-item dropdown-link text-white">
                  <Link
                    className="nav-link" to="/services">
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Services
                    </a>
                    <i className="bi bi-chevron-down ms-1" onClick={() => { setService(!service); setClicked(false); }} />
                  </Link>
                  <ul style={{ width: "300px !important", padding: '10px', marginLeft: '150px' }} className={service ? "" : "d-none"}>
                    <li className="nav-item dropdown-link ">
                      <li
                        className={`nav-link ${location.pathname === "" ? "active" : ""
                          }`}
                        style={{ fontSize: '19px' }}
                        onClick={() => setClicked(!clicked)}
                      >
                        Design <i className="bi bi-chevron-down ms-1" />
                      </li>
                      <ul className={clicked ? 'dropdown-link ' : "d-none"} style={{ padding: '10px', marginBottom: "100px", marginLeft: "-180px" }}>
                        <li className="nav-item dropdown-link">
                          <Link
                            className={`nav-link ${location.pathname === "/ui-ux" ? "active" : ""
                              }`}
                            to="/ui-ux"
                            onClick={toggleMenu}
                          >
                            UI/UX
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link ${location.pathname === "/graphics" ? "active" : ""
                              }`}
                            to="/graphics"
                            onClick={toggleMenu}
                          >
                            Graphics
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/software-development"
                      >
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/digital-marketing"
                      >
                        Digital Marketing
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className="nav-item text-white"
                  onClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                >
                  <Link className="nav-link" to="/case-studies">
                    Case Studies
                  </Link>
                </li>
                <li
                  className="nav-item text-white"
                  onClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                >
                  <Link className="nav-link" to="/contact-us">
                    Contact
                  </Link>
                </li>

                <li
                  className="nav-item text-white"
                  onClick={() => setClicked(false)}
                >
                  <Link className="nav-link" to="#" >
                    Hire Us On<i className="bi bi-chevron-down ms-1"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      onClick={() => setHire(!hire)} />
                  </Link>

                  {isDropdownVisible && (
                    <div className={hire ? "d-flex dropdown-menu" : "d-none"} style={{ marginLeft: '150px' }}>
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.upwork.com/agencies/1293525604405526528/"
                        >
                          <img
                            src={upwork}
                            alt="Upwork logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.behance.net/ditinus"
                        >
                          <img
                            src={behance}
                            alt="Behance logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                    </div>
                  )}
                </li>

                <li
                  onDoubleClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                  className="nav-item dropdown-link text-white">
                  <Link className="nav-link" to="/business-process-services" >
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Outsource With Us
                    </a>
                    <i className="bi bi-chevron-down ms-1" onClick={() => setOutsource(!outsource)} />
                  </Link>
                  <ul style={{ width: "290px", marginLeft: '20px', justifyContent: "center" }} className={outsource ? "" : "d-none"}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/business-process-services"
                      >
                        Business Process Services
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div >
      <div
        className={`web-nav-sidebar navbar-toggler ${isWebMenuOpen ? 'showMenu' : ''}`}
      >
        <div className="row cover-web-menu col-12 p-0 m-0 position-relative" style={{ overflow: 'scroll' }}>
          <button
            type="button"
            onClick={toggleWebMenu}
            className="text-hover-gold toggleBtnClose border-0 w-auto position-absolute bg-transparent mx-auto p-3 p-md-3 p-xl-5 p-lg-5 m-0 z-1"
            style={{ right: 0 }}
          >
            <i className="bi bi-x-lg text-white fs-2" />
          </button>
          <div
            style={{ overflow: 'scroll' }}
            className="p-0 m-0 col-xl-3 col-xxl-3 col-lg-4 pb-xl-0 pb-lg-3 col-sm-4 col-md-4 col-6 position-absolute mx-auto p-3 p-md-3 p-xl-5 p-lg-5 menu-main-logo z-1">
            <img loading="lazy" className="web-nav-logo2 img-fluid" src={DitinusLogo} alt="img" />
          </div>
          {/* Left Side Menu starts */}
          {isWebMenuOpen &&
            <motion.div
              className={`col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex web-menu-link-section d-flex flex-column h-auto justify-content-between`}
              initial={{ opacity: 0, x: -200 }}  // start off-screen to the left
              animate={{ opacity: 1, x: 0 }}     // animate to the normal position
              exit={{ opacity: 0, x: -200 }}     // move off-screen to the left again
              transition={{ duration: 2 }}       // duration of the fade and slide effect
            >
              <div className="col-xl-12 col-xxl-11 col-lg-12 row p-0 m-0">
                <div className="col-xl-5 col-xxl-4 col-lg-5 col-sm-12 col-12 p-0 m-0 d-flex flex-column gap-1 gap-lg-3 gap-xl-3">
                  {/* Company Section */}
                  <div className="col-xl-12 col-lg-12 col-md-10 col-10 p-0 m-0 d-flex flex-column gap-3">
                    {isDesktop ? (
                      <>
                        <Link to="/" onClick={toggleWebMenu} className="d-flex flex-row justify-content-between">
                          <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">Company</h5>
                          {!isDesktop && <img src={downArrow} alt="" />}
                        </Link>
                        <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-2 py-xl-3 py-lg-3">
                          <Link to="/about-us" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">About</p>
                          </Link>
                          <Link to="/training" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Training</p>
                          </Link>
                          <Link to="/career" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Career</p>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="accordion accordion-flush navbar-accordion" id="accordionFlushExample">
                        <div className="accordion-item" style={{ backgroundColor: 'transparent' }}>
                          <h2 className="accordion-header ">
                            <button
                              className={`accordion-button web-nav-form-desc ${openAccordion === 'company' ? 'collapsed' : ''}  fw-bold p-0 pb-2 bg-transparent  border-bottom`}
                              type="button"
                              onClick={() => handleAccordionToggle('company')}
                              aria-expanded={openAccordion === 'company' ? 'true' : 'false'}
                              aria-controls="flush-collapseCompany"
                            >
                              Company
                            </button>
                          </h2>
                          <div
                            id="flush-collapseCompany"
                            className={`accordion-collapse collapse ${openAccordion === 'company' ? 'show' : ''}`}
                            style={{ backgroundColor: 'transparent' }}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body bg-transparent p-0 m-0 border-0">
                              <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-2 py-xl-3 py-lg-3">
                                <Link to="/about-us" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white">About</p>
                                </Link>
                                <Link to="/training" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white">Training</p>
                                </Link>
                                <Link to="/career" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white">Career</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-10 col-10 p-0 m-0 d-flex flex-column gap-3">
                    {isDesktop ? (
                      <>
                        <Link to="services" onClick={toggleWebMenu} className="d-flex flex-row justify-content-between">
                          <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">Services</h5>
                          {!isDesktop && <img src={downArrow} alt="" />}
                        </Link>
                        <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-3">
                          <Link to="/ui-ux" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Design</p>
                          </Link>
                          <Link to="/ui-ux" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-normal">UI/UX</p>
                          </Link>
                          <Link to="/graphics" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-normal">Graphics</p>
                          </Link>
                          <Link to="/developent" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Development</p>
                          </Link>
                          <Link to="/digital-marketing" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Digital Marketing</p>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="accordion accordion-flush navbar-accordion" id="accordionFlushExample">
                        <div className="accordion-item" style={{ backgroundColor: 'transparent' }}>
                          <h2 className="accordion-header">
                            <button
                              className={`accordion-button web-nav-form-desc ${openAccordion === 'services' ? 'collapsed' : ''}  fw-bold p-0 pb-2 bg-transparent  border-bottom`}
                              type="button"
                              onClick={() => handleAccordionToggle('services')}
                              aria-expanded={openAccordion === 'services' ? 'true' : 'false'}
                              aria-controls="flush-collapseServices"
                            >
                              Services
                            </button>
                          </h2>
                          <div
                            id="flush-collapseServices"
                            className={`accordion-collapse collapse ${openAccordion === 'services' ? 'show' : ''}`}
                            style={{ backgroundColor: 'transparent' }}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body bg-transparent p-0 m-0 border-0">
                              <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-3">
                                <Link to="/ui-ux" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-bold">Design</p>
                                </Link>
                                <Link to="/ui-ux" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-normal">UI/UX</p>
                                </Link>
                                <Link to="/graphics" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-normal">Graphics</p>
                                </Link>
                                <Link to="/development" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-bold">Development</p>
                                </Link>
                                <Link to="/digital-marketing" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-bold">Digital Marketing</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                </div>

                <div className="col-xl-2 col-xxl-4 col-lg-2 col-0 p-0 m-0">
                  {/* Empty Section */}
                </div>

                {/* Outsource Section */}
                <div className="col-xl-5 col-xxl-4 col-lg-5 col-12 p-0 m-0 d-flex flex-column gap-1 gap-lg-3 gap-xl-3 ">
                  <div className="col-xl-12 col-lg-12 col-md-10 col-10 p-0 m-0 d-flex flex-column gap-3">
                    {isDesktop ? (
                      <>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">
                            Outsource With Us
                          </h5>
                          {!isDesktop && <img src={downArrow} alt="" />}
                        </div>
                        <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-3">
                          <Link to="business-process-services" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Business Process Services</p>
                          </Link>
                          <Link to="hire-our-developers" onClick={toggleWebMenu}>
                            <p className="p-0 m-0 text-white fw-bold">Hire Our Developers</p>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="accordion accordion-flush navbar-accordion" id="accordionFlushExample">
                        <div className="accordion-item" style={{ backgroundColor: 'transparent' }}>
                          <h2 className="accordion-header ">
                            <button
                              className={`accordion-button web-nav-form-desc ${openAccordion === 'outsource' ? 'collapsed' : ''}  fw-bold p-0 pb-2 bg-transparent  border-bottom`}
                              type="button"
                              onClick={() => handleAccordionToggle('outsource')}
                              aria-expanded={openAccordion === 'outsource' ? 'true' : 'false'}
                              aria-controls="flush-collapseOutsource"
                            >
                              <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">
                                Outsource With Us
                              </h5>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOutsource"
                            className={`accordion-collapse collapse ${openAccordion === 'outsource' ? 'show' : ''}`}
                            style={{ background: 'transparent' }}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body bg-transparent p-0 m-0 border-0">
                              <div className="col-12 p-0 m-0 d-flex flex-column gap-3 border-top py-3">
                                <Link to="business-process-services" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-bold">Business Process Services</p>
                                </Link>
                                <Link to="hire-our-developers" onClick={toggleWebMenu}>
                                  <p className="p-0 m-0 text-white fw-bold">Hire Our Developers</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={`col-xl-12 col-lg-12 col-md-10 col-10 p-0 m-0 ${!isDesktop ? "border-bottom border-1 pb-2" : ""}`}>
                    <Link
                      to={'case-studies'}
                      onClick={toggleWebMenu}
                    >

                      <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">Case Studies</h5>
                    </Link>
                  </div>

                  <div className={`col-xl-12 col-lg-12 col-md-10 col-10 p-0 m-0 mt-xxl-4 mt-xl-4 mt-lg-3`}>
                    <Link
                      to={'contact-us'}
                      onClick={toggleWebMenu}
                    >
                      <h5 className="p-0 m-0 text-white web-nav-form-desc text-start">Contact</h5>
                    </Link>
                  </div>

                </div>
              </div>
              {/* Footer Section */}
              <div className={`col-xl-12 col-xxl-11 col-lg-12 col-12 col-sm-12 col-md-12 row p-0 m-0 ${isDesktop ? "border-top mt-3" : ""}`}>
                <div className="row">

                  <div className="col-7 col-sm-6 col-md-6 col-lg-4 col-xl-4 p-0 m-0 mt-4">
                    <h5 className="web-menu-footer text-white">Follow Us On:</h5>
                    <div className="d-flex flex-row gap-3 gap-lg-5 gap-xl-5 gap-md-3 gap-sm-3 gap-xxl-5 mt-2 mt-lg-4 mt-xl4 mt-xxl-4 mt-md-3 mt-sm-3">
                      <Link to="https://www.facebook.com/ditinus/">  <img src={facebook_logo} alt="Facebook" className="logo-nav-menu" /></Link>
                      <Link to="https://www.instagram.com/ditinus/">     <img src={instagram_logo} alt="Instagram" className="logo-nav-menu" /></Link>
                      <Link to="https://www.linkedin.com/company/ditinus-technology-pvt-ltd">        <img src={linded_in_logo} alt="LinkedIn" className="logo-nav-menu" /></Link>
                    </div>
                  </div>
                  <div className="col-1 col-sm-1 col-md-1 col-lg-4 col-xl-4 p-0 m-0">
                    {/* Empty Section */}
                  </div>
                  <div className="col-4 col-sm-5 col-md-5 col-lg-4 col-xl-4 p-0 m-0 mt-4">
                    <h5 className="web-menu-footer text-white">Hire Us On:</h5>
                    <div className="d-flex flex-row align-items-center gap-3 gap-lg-5 gap-xl-5 gap-md-3 gap-sm-3 gap-xxl-5 mt-2 mt-lg-4 mt-xl4 mt-xxl-4 mt-md-3 mt-sm-3">
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        target="_blank"
                        to="/https://www.upwork.com/agencies/1293525604405526528/"
                      >   <img src={upwork_logo} alt="Upwork" className="logo-nav-menu" />
                      </Link>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        target="_blank"
                        to="/https://www.behance.net/ditinus"
                      > <img src={behance_logo} alt="Behance" className="logo-nav-menu" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          }
          {/* Left Side Menu ends */}
          {/* Right Side (Form Section) */}
          <motion.div
            className={`col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 p-0 m-0 web-menu-form flex-column align-items-center justify-content-center d-flex py-5 px-2 px-sm-3 px-md-5 px-lg-3 px-xl-2 px-xxl-5 nav-menu-left`}
            initial={{ opacity: 0, y: 200 }}  // start off-screen to the left
            animate={{ opacity: 1, y: 0 }}     // animate to the normal position
            exit={{ opacity: 0, y: 200 }}     // move off-screen to the left again
            transition={{ duration: 2 }}       // duration of the fade and slide effect
          >


            <div className="col-12 p-0 m-0 flex-column d-flex justify-content-center h-auto gap-0 gap-lg-3 gap-xl-3 ">
              <h3 className="p-0 m-0 text-white web-nav-form-header2 text-start">Let's lift up your business!</h3>
              <p className="p-0 m-0 text-white web-nav-form-desc2 text-start">
                Ready to start creating an amazing business?
              </p>
              <form className="col-12 d-flex flex-column gap-3 mt-2">
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  className="formInput m-0 w-100 rounded-5"
                />
                <a className="transition text-nowrap rounded-pill fill-btn4 d-flex justify-content-center">
                  Get a quote
                </a>
              </form>
            </div>

          </motion.div>
          {/* Right Side (Form Section) ends */}
        </div>
      </div>
    </div >
  );
};

export default Header;
